import Vue from 'vue';
import VueRouter from 'vue-router';

import ROUTES from '@/constants/routes.constant';
import store from '@/store';
import { MODULE, COMMON } from '@/constants/store.constant';
import AUTH from '@/constants/auth.constant';
import FEATURE_FLAGS from '@/constants/feature-flags';

Vue.use(VueRouter);

const routes = [
  {
    path: '/sign-in',
    name: ROUTES.SIGN_IN,
    component: () => import(/* webpackChunkName: "sign-in" */ '@/views/SignInPage'),
  },
  {
    path: '/select-app',
    name: ROUTES.SELECTAPP,
    meta: { requiresAuth: true, layout: () => import('@/components/layout/Empty.vue') },
    component: () => import(/* webpackChunkName: "providers" */ '@/views/SelectAppPage'),
  },
  {
    path: '/providers-em',
    name: ROUTES.PROVIDERS_EM,
    meta: { requiresAuth: true, layout: () => import('@/components/layout/PageShellEM.vue') },
    component: () => import(/* webpackChunkName: "providers" */ '@/views/ExchangeMonitor/ProvidersEMPage'),
  },
  {
    path: '/provider-em/:id?',
    name: ROUTES.EXCHANGE_MONITOR,
    component: () => import(/* webpackChunkName: "exchangeMonitor" */ '@/views/ExchangeMonitor/ProviderEMPage'),
    meta: { requiresAuth: true, layout: () => import('@/components/layout/PageShellEM.vue') },
  },
  {
    path: '/collection-em',
    name: ROUTES.COLLECTION_EM,
    meta: { requiresAuth: true, layout: () => import('@/components/layout/PageShellEM.vue') },
    component: () => import(/* webpackChunkName: "providers" */ '@/views/ExchangeMonitor/CollectionPageEM'),
  },
  {
    path: '/history-em',
    name: ROUTES.HISTORY_EM,
    meta: { requiresAuth: true, layout: () => import('@/components/layout/PageShellEM.vue') },
    component: () => import(/* webpackChunkName: "historyEM" */ '@/views/ExchangeMonitor/HistoryEMPage'),
  },
  {
    path: '/crawled-documents/:id?',
    name: ROUTES.CRAWLED_DOCUMENTS,
    meta: { requiresAuth: true, layout: () => import('@/components/layout/PageShellEM.vue') },
    component: () => import(/* webpackChunkName: "crawledDocuments" */ '@/views/ExchangeMonitor/CrawledDocumentsEMPage'),
  },
  {
    path: '/user-management',
    name: ROUTES.USER_MANAGEMENT,
    meta: { requiresAuth: true, requiredPermission: 'canViewUserManagement' },
    component: () => import(/* webpackChunkName: "user-management" */ '@/components/layout/PageShellUserManagement'),
    redirect: { name: ROUTES.USER_MANAGEMENT.LIST },
    children: [
      {
        path: 'list',
        name: ROUTES.USER_MANAGEMENT.LIST,
        component: () => import(/* webpackChunkName: "list" */ '@/views/UserManagement/UserListPage'),
        meta: { requiresAuth: true, requiredPermission: 'canViewUserManagement' },
      },
      {
        path: 'tenants',
        name: ROUTES.USER_MANAGEMENT.TENANTS,
        component: () => import(/* webpackChunkName: "tenants" */ '@/views/UserManagement/TenantsListPage'),
        meta: { requiresAuth: true, requiredPermission: 'canViewUserManagement' },
      }
    ],
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "shell" */ '@/components/layout/PageShell'),
    meta: { requiresAuth: true },
    children: [
      {
        name: ROUTES.DAP,
        path: '',
        meta: { requiresAuth: true },
        redirect: { name: ROUTES.SELECTAPP },
      },
      {
        path: 'providers',
        name: ROUTES.PROVIDERS,
        component: () => import(/* webpackChunkName: "providers" */ '@/views/ProvidersPage'),
        meta: { requiresAuth: true },
      },
      {
        path: 'provider/:id?',
        name: ROUTES.PROVIDER,
        component: () => import(/* webpackChunkName: "provider" */ '@/views/ProviderPage'),
        meta: { requiresAuth: true },
      },
      {
        path: 'collection',
        name: ROUTES.COLLECTION,
        component: () => import(/* webpackChunkName: "collection" */ '@/views/CollectionPage'),
        meta: { requiresAuth: true },
      },
      {
        path: 'job/:jobId/link/:linkId',
        name: ROUTES.JOB,
        component: () => import(/* webpackChunkName: "job" */ '@/views/JobPage'),
        meta: { requiresAuth: true },
      },
      {
        path: 'pr-ec',
        name: ROUTES.PREC,
        component: () => import(/* webpackChunkName: "prec" */ '@/views/CollectionPrEcPage'),
        meta: { requiresAuth: true },
      },
      {
        path: '*',
        name: 'EMPTY',
        component: () => import(/* webpackChunkName: "not-found" */ '@/views/NotFoundPage'),
        meta: { requiresAuth: false },
      }
    ],
  }
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem(AUTH.USER_KEY);

  if (!store.getters[`${MODULE.AUTH}/${COMMON.GET_STATE}`]('isLoggedIn') && isLoggedIn) { store.dispatch(`${MODULE.AUTH}/${AUTH.INIT_SESSION}`); }

  const isPrivate = to.matched.some((record) => record.meta.requiresAuth);
  const requiredPermission = to.meta.requiredPermission;

  // Block EM application when enableEM FF is not active
  for (const [key, value] of Object.entries(FEATURE_FLAGS)) {
    if (to.query[value]) {
      store.commit(`${MODULE.FEATURE_FLAGS}/${COMMON.SET_STATE}`, { name: FEATURE_FLAGS[key], value: to.query[value] === 'true' });
    }
  }

  const enableEM = store.getters[`${MODULE.FEATURE_FLAGS}/${COMMON.GET_STATE}`](FEATURE_FLAGS.ENABLE_EM);
  if (to.name === ROUTES.PROVIDERS_EM && !enableEM) {
    return next({
      name: 'EMPTY',
      query: { redirect: '/not-found' },
    });
  }

  if (isPrivate && !isLoggedIn) {
    return next({
      name: ROUTES.SIGN_IN,
      query: { redirect: to.fullPath },
    });
  }

  if (isLoggedIn && to.name === ROUTES.SIGN_IN) {
    return next({ name: ROUTES.PROVIDER });
  }

  // Check permissions
  if (requiredPermission && !store.getters[`${MODULE.PERMISSIONS}/${COMMON.GET_STATE}`](requiredPermission)) {
    return next({
      name: 'EMPTY',
      query: { redirect: '/not-found' },
    });
  }

  next();
});

export default router;
