const ROUTES = {
  SIGN_IN: 'SIGN_IN',
  DAP: 'DAP',
  PROVIDERS: 'Providers',
  PROVIDER: 'Provider',
  COLLECTION: 'Collection',
  JOB: 'Job',
  SELECTAPP: 'SELECT_APP',
  PROVIDERS_EM: 'PROVIDERS_EM',
  COLLECTION_EM: 'COLLECTION_EM',
  EXCHANGE_MONITOR: 'EXCHANGE_MONITOR',
  CRAWLED_DOCUMENTS: 'CRAWLED_DOCUMENTS',
  HISTORY_EM: 'HISTORY_EM',
  PREC: 'PR_EC',
  USER_MANAGEMENT: 'USER_MANAGEMENT',
};

ROUTES.USER_MANAGEMENT = {
  ...ROUTES.USER_MANAGEMENT,
  LIST: 'LIST',
  TENANTS: 'TENANTS',
};

export default ROUTES;
