import { COMMON, MODULE } from '@/constants/store.constant';
import { getLastJobExecutions } from '@/services/api/pr-ec.service';

const filterJobsByName = (jobs, filterText) => {
  return filterText === null ? jobs : jobs.filter((job) => job.translatedName.toLowerCase().includes(filterText.toLowerCase()) ||
  job.companyId.toLowerCase().includes(filterText.toLowerCase()) || job.link.toLowerCase().includes(filterText.toLowerCase()));
};

const initialState = () => ({
  jobs: [],
  filterJobs: [],
  params: {
    page: 0,
    size: 40,
  },
  isLastPage: true,
  filterText: '',
  startDate: null,
  endDate: null,
  totalElements: 0,
  shouldRefresh: false,
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    [COMMON.UPDATE_STATE] (state, { name, value }) {
      state[name] = value;
    },
    [COMMON.UPDATE_PARAM] (state, { name, value }) {
      state.params[name] = value;
    },
    [COMMON.RESET_STATE] (state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    async [COMMON.FETCH_DATA] ({ state, commit, rootGetters }, { shouldReset }) {
      commit(COMMON.UPDATE_PARAM, { name: 'page', value: shouldReset ? 0 : state.params.page + 1 });

      const currentTenant = rootGetters[`${MODULE.AUTH}/${COMMON.GET_STATE}`]('currentTenant');

      let data;
      if (state.jobs.length > 0 && !state.shouldRefresh) {
        data = state.jobs;
      } else {
        const result = await getLastJobExecutions({ headers: { 'X-Tenant-Code': currentTenant } });
        data = result.data;
      }
      commit(COMMON.UPDATE_STATE, { name: 'jobs', value: data });
      const filteredData = filterJobsByName(data, state.filterText);
      commit(COMMON.UPDATE_STATE, { name: 'filterJobs', value: filteredData });
    },
    [COMMON.FILTER_DATA] ({ state, commit }) {
      const data = filterJobsByName(state.jobs, state.filterText);
      commit(COMMON.UPDATE_STATE, { name: 'filterJobs', value: data });
    },
  },
  getters: {
    [COMMON.GET_STATE] (state) {
      return state.filterJobs;
    },
    [COMMON.IS_LAST_PAGE] (state) {
      return state.isLastPage;
    },
    [COMMON.SHOULD_REFRESH] (state) {
      return state.shouldRefresh;
    },
    [COMMON.GET_STATE_BY_NAME] (state) {
      return (name) => state[name];
    },
  },
};
