export default {
  LOG_IN: 'LOG_IN',
  LOG_OUT: 'LOG_OUT',
  INIT_SESSION: 'INIT_SESSION',
  UPDATE_SESSION: 'UPDATE_SESSION',
  USER_KEY: 'USER_KEY',
  USER_INFO: 'USER_INFO',
  UPDATE_TENANTS: 'UPDATE_TENANTS',
  SET_CURRENT_TENANT: 'SET_CURRENT_TENANT',
};
