import http from '@/plugins/http';
import ENDPOINTS from '@/constants/endpoints.constant';

/**
 * @param {object} [params] Pagination information
 * @param {number} [params.page] Page number
 * @param {number} [params.size] Amount of jobs per page
 * @param {string} [params.name] Name of the job
 * @param {boolean} [params.status] Status of the job
 * @param {object} [config] Additional config options, e.g., headers
 * @returns {promise} Promise object containing all jobs that match the given params
 */
export const getJobs = (params, config = {}) => http.get(ENDPOINTS.JOB, { params, ...config });

/**
 * @param {number} id Id of the job
 * @returns {promise} Promise object containing all provider links that the given job has
 */
export const getProviderLinksByJobId = (id) => http.get(`${ENDPOINTS.JOB}/${id}/link/`);

/**
 * @param {object} path Path parameters of the job
 * @param {number} path.jobId Id of the job
 * @param {number} path.linkId Id of the provider link
 * @param {string} path.type Type of link, it could be either 'visited/success', 'visited/error', 'file' or 'pending'
 * @param {object} [params] Pagination information
 * @param {number} [params.page] Page number
 * @param {number} [params.size] Amount of jobs per page
 * @param {string} [params.sort] Field name and order
 * @param {string} [params.statusCode] Status code of the link request
 * @param {string} [params.filesFoundCount] Amount of files found
 * @param {string} [params.uuid] Unique ID
 * @param {string} [params.parentLink] Link of the website where the link is located
 * @param {string} [params.linkText] Text of the anchor tag
 * @param {string} [params.pageTitle] Title of the page
 * @param {string} [params.headers] Headers of the link
 * @param {string} [params.extension] Extension of the document
 * @param {string} [params.timeZone] Timezone of the analyst
 * @returns {promise} Promise object containing all the crawled links that the given job and provider link has
 */
export const getJobLinks = ({ jobId, linkId, type }, params) => http.get(`${ENDPOINTS.JOB}/${jobId}/link/${linkId}/${type}`, { params });

/**
 * @param {object} path Path parameters of the job
 * @param {number} path.jobId Id of the job
 * @param {number} path.linkId Id of the provider link
 * @param {object} [payload] Pagination information
 * @param {string} [payload.s3Path] Path of the file on S3
 * @returns {promise} Promise object containing all the crawled links that the given job and provider link has
 */
export const getS3File = ({ jobId, linkId }, payload) => http.post(`${ENDPOINTS.JOB}/${jobId}/link/${linkId}/file/download`, payload, { responseType: 'blob' });

/**
  * @param {object} path Path parameters of the job
  * @param {number} path.jobId Id of the job
  * @returns {promise} Promise object containing all the crawled links that the given job and provider link has
  */
export const getJobReport = ({ jobId }) => http.get(`${ENDPOINTS.JOB}/${jobId}/link/excel-report`, { responseType: 'blob' });

/**
 * @param {object} payload Initial job information
 * @param {string} payload.frequency Job frequency, it could be either 'DAILY', 'WEEKLY' or 'MONTHLY'
 * @param {number} payload.provider.id Id of the provider to be linked to the job
 * @returns {promise} Promise object containing information of the created job
 */
export const postJob = (payload) => http.post(ENDPOINTS.JOB, payload);

/**
 * @param {object} payload Job properties to be uploaded
 * @param {string} payload.frequency Job frequency, it could be either 'DAILY', 'WEEKLY' or 'MONTHLY'
 * @returns {promise} Promise object containing the updated job
 */
export const patchJob = ({ id, ...payload }) => http.patch(`${ENDPOINTS.JOB}/${id}`, payload);

/**
 * @param {number} path.jobId Id of the job
 * @returns {promise} Promise object containing the updated job
 */
export const scheduleJobNow = ({ id }) => http.put(`${ENDPOINTS.JOB}/${id}/schedule-now`);

/**
 * @param {object} path Path parameters of the job
 * @param {number} path.jobId Id of the job
 * @param {number} path.linkId Id of the provider link
 * @param {object} payload Job properties to be uploaded
 * @param {string} payload.link Visited link
 * @param {boolean} payload.enabledNextExecution Whether the visited link should be enabled or disabled
 * @returns {promise} Promise object containing the updated job
 */
export const patchVisitedLink = ({ jobId, linkId }, payload) => http.patch(`${ENDPOINTS.JOB}/${jobId}/link/${linkId}/visited/`, payload);
