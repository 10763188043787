import { COMMON, MODULE } from '@/constants/store.constant';
import { getJobs } from '@/services/api/job.service';

const initialState = () => ({
  jobs: [],
  params: {
    page: 0,
    size: 40,
    name: null,
    status: null,
    sort: null,
  },
  isLastPage: false,
  totalElements: 0,
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    [COMMON.UPDATE_STATE] (state, { name, value }) {
      state[name] = value;
    },
    [COMMON.UPDATE_PARAM] (state, { name, value }) {
      state.params[name] = value;
    },
    [COMMON.RESET_STATE] (state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    async [COMMON.FETCH_DATA] ({ state, commit, rootGetters }, { shouldReset }) {
      commit(COMMON.UPDATE_PARAM, { name: 'page', value: shouldReset ? 0 : state.params.page + 1 });

      const currentTenant = rootGetters[`${MODULE.AUTH}/${COMMON.GET_STATE}`]('currentTenant');

      const { data: { content: jobs, last: isLastPage, totalElements } } = await getJobs(state.params, { headers: { 'X-Tenant-Code': currentTenant } });
      commit(COMMON.UPDATE_STATE, { name: 'jobs', value: shouldReset ? jobs : [...state.jobs, ...jobs] });
      commit(COMMON.UPDATE_STATE, { name: 'isLastPage', value: isLastPage });
      commit(COMMON.UPDATE_STATE, { name: 'totalElements', value: totalElements });
    },
  },
  getters: {
    [COMMON.GET_STATE] (state) {
      return (name) => state[name];
    },
    [COMMON.IS_LAST_PAGE] (state) {
      return state.isLastPage;
    },
  },
};
