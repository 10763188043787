import { COMMON } from '@/constants/store.constant';

const initialState = () => ({
  canViewUserManagement: false,
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    [COMMON.SET_STATE] (state, { name, value }) {
      state[name] = value;
    },
    [COMMON.RESET_STATE] (state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    updatePermissions ({ commit }, tenants) {
      const hasAdminPermission = tenants.some((tenant) => tenant.code === 'ADMIN');

      commit(COMMON.SET_STATE, { name: 'canViewUserManagement', value: hasAdminPermission });
    },
  },
  getters: {
    [COMMON.GET_STATE] (state) {
      return (name) => state[name];
    },
  },
};
