import http from '@/plugins/http';
import ENDPOINTS from '@/constants/endpoints.constant';

/**
 * @returns {Promise} Promise object containing all users
 */
export const getUsers = () => {
  return http.get(ENDPOINTS.USERS);
};

/**
 * @param {number} userId ID of the user to be updated
 * @param {object} updatedUser Updated user details
 * @param {string} updatedUser.userName Updated username of the user
 * @param {string} updatedUser.fullName Updated full name of the user
 * @param {string} updatedUser.email Updated email address of the user
 * @returns {promise} Promise object representing the result of the operation
 */
export const updateUser = ({ id, ...payload }) => http.put(`${ENDPOINTS.USERS}/${id}`, payload);

/**
 * @param {string} username The username of the user to be fetched
 * @returns {promise} Promise object containing the user details
 */
export const getUserByUsername = (username) => http.get(`${ENDPOINTS.USERS}/alias/${username}`);

/**
 * @param {object} newUser User details
 * @param {string} newUser.userName Username of the new user
 * @param {string} newUser.fullName Full name of the new user
 * @param {string} newUser.email Email address of the new user
 * @returns {promise} Promise object representing the result of the operation
 */
export const createUser = (newUser) => http.post(ENDPOINTS.USERS, newUser);

/**
 * @param {number} userId ID of the user to be deleted
 * @returns {promise} Promise object representing the result of the operation
 */
export const deleteUser = (userId) => http.delete(`${ENDPOINTS.USERS}/${userId}`);
