import { COMMON, MODULE } from '@/constants/store.constant';
import { getProviders } from '@/services/api/provider.service';

const initialState = () => ({
  providers: [],
  params: {
    page: 0,
    size: 40,
    nameOrCompanyId: null,
    enabled: null,
  },
  isLastPage: false,
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    [COMMON.UPDATE_STATE] (state, { name, value }) {
      state[name] = value;
    },
    [COMMON.UPDATE_PARAM] (state, { name, value }) {
      state.params[name] = value;
    },
    [COMMON.RESET_STATE] (state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    async [COMMON.FETCH_DATA] ({ state, commit, rootGetters }, { shouldReset }) {
      commit(COMMON.UPDATE_PARAM, { name: 'page', value: shouldReset ? 0 : state.params.page + 1 });

      const currentTenant = rootGetters[`${MODULE.AUTH}/${COMMON.GET_STATE}`]('currentTenant');

      const { data: { content: providers, last: isLastPage } } = await getProviders(state.params, { headers: { 'X-Tenant-Code': currentTenant } });
      commit(COMMON.UPDATE_STATE, { name: 'providers', value: shouldReset ? providers : [...state.providers, ...providers] });
      commit(COMMON.UPDATE_STATE, { name: 'isLastPage', value: isLastPage });
    },
  },
  getters: {
    [COMMON.GET_STATE] (state) {
      return state.providers;
    },
    [COMMON.IS_LAST_PAGE] (state) {
      return state.isLastPage;
    },
  },
};
