export default {
  LOGIN: '/login',
  LOGOUT: '/logout',
  PROVIDER: '/provider',
  COMPANY: '/company',
  JOB: '/job',
  COVERAGE: '/coverage',
  EXCHANGE_MONITORS: '/em',
  JOB_SHEDULER: '/em/job_schedulers',
  PR_EC: '/pr-ec',
  PR_EC_JOBS: '/jobs',
  S3_CONFIGS: '/s3-configs',
  USERS: '/users',
  TENANTS: '/tenants',
};
